export const useCache = () => {
    // use composable from nuxt-cache-keys
    const { addCacheKey } = useCacheKeys();

    const addProductCacheTag = (productId: string) => {
        addCacheKey('product-' + productId);
    };

    const addProductStreamCacheTag = (productStreamId: string) => {
        addCacheKey('product-stream-' + productStreamId);
    };

    const addProductListingCacheTag = (productId: string) => {
        addCacheKey('product-listing-route-' + productId);
    };

    const addCategoryCacheTag = (categoryId: string) => {
        addCacheKey('category-route-' + categoryId);
    };

    const addNavigationRouteCacheTag = (categoryId: string) => {
        addCacheKey('navigation-route-' + categoryId);
    };

    const addBaseNavigationCacheTag = () => {
        addCacheKey('base-navigation');
    };

    const addCmsPageCacheTag = (cmsPageId: string) => {
        addCacheKey('cms-page-' + cmsPageId);
    };

    const addLandingPageCacheTag = (landingPageId: string) => {
        addCacheKey('landing-page-route-' + landingPageId);
    };

    return {
        addCacheKey,
        addProductCacheTag,
        addProductStreamCacheTag,
        addProductListingCacheTag,
        addCategoryCacheTag,
        addNavigationRouteCacheTag,
        addBaseNavigationCacheTag,
        addCmsPageCacheTag,
        addLandingPageCacheTag,
    };
};
